<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			资讯管理
			<span>&gt;</span>
			新闻资讯
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<div style="margin:20px;">
				<el-row style="text-align: center;">
					<el-button class="search-range" type="primary" icon="el-icon-circle-plus-outline" @click="addArticle()" plain>增加新闻</el-button>
					<el-input class="search-range" style="width:150px" v-model="condition.title" placeholder="搜索标题"></el-input>
					<el-input class="search-range" style="width:150px" v-model="condition.text" placeholder="搜索内容"></el-input>
					<el-date-picker
						class="search-range"
						v-model="condition.timeRange"
						type="datetimerange"
						:picker-options="pickerOptions"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						align="right"
					>
					</el-date-picker>
					<el-button class="search-range" type="primary" icon="el-icon-search" @click="listArticle()">搜索</el-button>
				</el-row>
				<el-table :data="articleList" stripe style="width: 100%;margin-top:4px;">
					<el-table-column prop="title" label="文章标题"> </el-table-column>
					<el-table-column prop="category.label" label="文章分类" width="180"> </el-table-column>
					<el-table-column prop="top" label="权重" width="100"> </el-table-column>
					<el-table-column prop="release_time" label="发布时间" width="210"> </el-table-column>
					<el-table-column prop="update_time" label="修改时间" width="210"> </el-table-column>
					<el-table-column label="" width="200">
						<template slot-scope="scope">
							<el-button type="primary" icon="el-icon-search" circle @click="viewDetail(scope.row.id)"></el-button>
							<el-button type="primary" icon="el-icon-edit" circle @click="editArticle(scope.row.id)"></el-button>
							<el-button type="danger" icon="el-icon-delete" circle @click="deleteArticle(scope.row.id)"></el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-pagination
					style="margin:10px 0px 0px 0px;text-align:center"
					background
					layout="total, prev, pager, next"
					:page-size="pagination.size"
					:total="pagination.total"
					:pager-count="7"
					@current-change="changePage"
				>
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
import { apiListArticleByCondition, apiDeleteArticle } from "@/api/admin/article.js";
export default {
	data() {
		return {
			condition: {
				title: "",
				text: "",
				categoryLabel: "新闻资讯",
				//timeRange: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
				timeRange: null
			},
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit("pick", [start, end]);
						}
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit("pick", [start, end]);
						}
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit("pick", [start, end]);
						}
					}
				]
			},
			articleList: [],
			pagination: {
				start: 0,
				size: 10,
				total: 0
			}
		};
	},
	methods: {
		handleChange(value) {
			//console.log(value);
			//console.log("this.chooseCategory=", this.chooseCategory);
			this.condition.categoryId = this.chooseCategory[this.chooseCategory.length - 1];
			//console.log("this.condition.categoryId=", this.condition.categoryId);
		},
		// 获取文章列表
		async listArticle() {
			try {
				//console.log("start_time", new Date(0, 1));
				//console.log("end_time", new Date(9999, 1));
				//console.log(this.pagination);
				//console.log(this.condition);
				let formData = new FormData();
				formData.append("start", this.pagination.start);
				formData.append("limit", this.pagination.size);
				formData.append("title", this.condition.title);
				formData.append("text", this.condition.text);
				formData.append("category_label", this.condition.categoryLabel);
				if (this.condition.timeRange === null || this.condition.timeRange === "") {
					formData.append("start_time", new Date(0, 1));
					formData.append("end_time", new Date(9999, 1));
				} else {
					formData.append("start_time", this.condition.timeRange[0]);
					formData.append("end_time", this.condition.timeRange[1]);
				}
				/* console.log("===========formData===========");
				for (var [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiListArticleByCondition(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					this.articleList = response.data.data.articleList;
					this.pagination.total = response.data.data.count;
				} else {
					this.$message.error(response.data.message || response.data.exception);
				}
			} catch (error) {
				console.log(error);
			}
		},
		addArticle() {
			this.$router.push({ path: "/pc/admin/news/add" });
		},
		viewDetail(id) {
			window.location.href = "/pc/admin/news/view?id=" + id;
		},
		editArticle(id) {
			window.location.href = "/pc/admin/news/edit?id=" + id;
		},
		// 删除文章
		async deleteArticle(id) {
			try {
				let formData = new FormData();
				formData.append("id", id);
				/* for (var [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiDeleteArticle(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					this.$message({ message: "成功删除", type: "success" });
					//执行成功1秒后刷新页面
					setTimeout(function() {
						location.reload();
					}, 1000);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		changePage(page) {
			this.pagination.start = (page - 1) * this.pagination.size;
			this.listArticle();
		}
	},
	mounted() {
		this.listArticle();
	}
};
</script>

<style lang="scss" scoped>
.search-range {
	margin-left: 10px;
	margin-top: 10px;
}
</style>
